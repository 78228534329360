import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import Title from "../components/Title"
import PublicationListItem from "../components/ListItems/PublicationListItem";
import Breadcrumb from "../components/Breadcrumb";

const PublicationList = ({pageContext, data, location}) => {
    const {
        currentPage,
        numPages,
    } = pageContext

    return (
        <Layout>
            <Metas title="Publications diverses"/>
            <section className="section page-content">
                <div className="container mx-auto px-4">
                    <Breadcrumb/>

                    <Title title="Publications diverses" className="text-primary"/>

                    <div className="flex flex-wrap my-8 -mx-2 items-center justify-start">
                    </div>

                    <div className="columns grid grid-cols-1 xl:grid-cols-2 gap-8">
                        {data.publications.nodes.map((node, index) => (
                            <div key={index} className="column publication">
                                <PublicationListItem content={node}/>
                            </div>
                        ))}
                    </div>

                    <Pagination
                        path={location.pathname}
                        currentPage={currentPage}
                        numPages={numPages}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default PublicationList

export const query = graphql`
    query publicationListQuery($skip: Int!, $limit: Int!) {
        publications: allNodePublication(
            sort: {fields: title, order: ASC},
            filter: {status: {eq: true}, relationships: {field_type_de_publication: {name: {eq: "Publication diverse"}}}},
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                title
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 247, maxHeight: 350, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    file: field_fichier {
                      localFile {
                        publicURL
                        prettySize
                      }
                    }
                }
            }
        }
    }`
